import PropTypes from 'prop-types';
import React from 'react';
//import Icon from "../assets/svg/dice-d20-solid.svg";
//import fsIcons from "../assets/svg/fs-icon2.svg";
//import {fsIcons} from "../images/Icon5_color.png";
import Icon from "./Icon";




const Header = props => {

  return (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon fa fa-spin"><Icon /></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>fibe<span className="custom-text">RS</span>w<span className="custom-text">IT</span>ch</h1>
        <p>
          Een oplossing voor al uw IT-projecten
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            //id="intro"
            //ref={ props.setButtonRef }
            disabled={props.double}
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            //id="oplossingen"
            //ref={ props.setButtonRef }
            disabled={props.double}
            onClick={() => {
              props.onOpenArticle('oplossingen')
            }}
          >
            Oplossingen
          </button>
        </li>
        <li>
          <button
            //id="Over"
            //ref={ props.setButtonRef }
            disabled={props.double}
            onClick={() => {
              props.onOpenArticle('over')
            }}
          >
            Over
          </button>
        </li>
        <li>
          <button
            disabled={props.double}
            onClick={() => {
              props.onOpenArticle('projecten')
            }}
          >
            Projecten
          </button>
        </li>
        <li>
          <button
            //id="contact"
            //ref={ props.setButtonRef }
            disabled={props.double}
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
  )
}

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
  double: PropTypes.bool,
}

export default Header
