import React, { useState, useEffect, useRef } from 'react'
//import { navigate } from 'gatsby';
import Layout from '../components/layout'
import useOnClickOutside from "../components/Hooks/useOnClickOutside"
import Header from '../components/Header'
import Main from '../components/Main'
import Footer from '../components/Footer'

const IndexPage = ( props ) => {
  const [isArticleVisible, setIsArticleVisible] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const [isDouble, setIsDouble] = useState(false);
  const [isArticleTimeout, setIsArticleTimeout] = useState(false);
  const [article, setArticle] = useState("");
  const [loading, setLoading] = useState("is-loading");
  const [myHash, setMyHash] = useState("");
  const wrapperRef = useRef();

  useOnClickOutside(wrapperRef, handleCloseArticle, isArticleVisible );
 
  useEffect(() => {
    const firstEntry = sessionStorage.getItem("firstEntry");
    let timeoutId = setTimeout(() => {
      setLoading("");      
      setMyHash(window.location.hash);
      if ( firstEntry === "true"  &&  myHash !== "" ) {
        setLoading("");
        if ( ! isArticleVisible  && ! loading ) {
          handleOpenArticle( myHash.replace("#", "") );
          setMyHash("");
          sessionStorage.setItem( "firstEntry", "false" );
        }
      }
    }, firstEntry === "true" ? 0 : 100);
    
    return function cleanup() {
      if ( timeoutId ) {
        clearTimeout( timeoutId );
      }
    }
  })
  
  function handleOpenArticle( article ) {
    setTimeout(() => {
      setIsArticleVisible( ! isArticleVisible );
    }, 100)
    //setIsArticleVisible( ! isArticleVisible );
    setArticle( article );
//    useOnClickOutside(wrapperRef, () => handleCloseArticle(), isArticleVisible );

    setIsDouble( ! isDouble );

    /*
    console.log ("btnRef", btnRef)
    if( btnRef.current ){
      btnRef.current.setAttribute("disabled", "disabled");
    }*/

    //navigate("/");
    //navigate(`#${article}`);

    setTimeout(() => {
      setIsTimeout( ! isTimeout );
    }, 325)

    setTimeout(() => {
      setIsArticleTimeout( ! isArticleTimeout );
    }, 350)
  }

  function handleCloseArticle( ) {
    //console.log("event", event)
    setIsArticleTimeout( ! isArticleTimeout );
    setIsDouble( ! isDouble );

    setTimeout(() => {
      setIsTimeout( ! isTimeout );
    }, 325)

    setTimeout(() => {
      setIsArticleVisible( ! isArticleVisible );
      setArticle( "" );
    }, 350)
  }

  return (
    <Layout location={ props.location }>
      <div className={`body ${ loading } ${ isArticleVisible ? 'is-article-visible' : '' }`}>
        <div id="wrapper">
          <Header 
            onOpenArticle={ handleOpenArticle }
            timeout={ isTimeout }
            double = { isDouble }
          />
          <Main
            timeout={ isTimeout }
            articleTimeout={ isArticleTimeout }
            article={ article }
            onCloseArticle={ handleCloseArticle }
            setWrapperRef={ wrapperRef }
          />
          <Footer timeout={ isTimeout } />
        </div>
        <div id="bg"></div>
      </div>
    </Layout>
  )
}
export default IndexPage;
