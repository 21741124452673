import PropTypes from 'prop-types';
import React from 'react';
import Logo from "../Logo";
//import { MapPin, Smartphone, Mail, Facebook, Bold } from 'react-feather';

import Form from './ContactForm';

const ContactPage = ( { contactBody, address, fb, phone, email, btw } ) => (
<>
          <div className="form-details">
            <Form />
          </div>
          <div className="contact-details">
            <section>
                    <div className="contact-method field half first">
                        <span className="icon alt fa-envelope">
                            <h3>E-mail</h3>
                        </span>
                        {email && (
                            <a href={ `mailto:${email}` }>
                                {email}
                            </a>
                            )}
                    </div>
                </section>
                <section style={{display:'none'}}>
                <div className="contact-method field half first">
                    <span className="icon alt fa-balance-scale">
                        <h3>BTW</h3>
                    </span>
                    
                    {btw && (
                    <a 
                        href={`https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?nummer=${encodeURI(
                        btw.replace("BE","").replace(/\s/g, "").replace(/\./g, "")
                    )}`} 
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                        {btw}
                    </a>
                    )}
                    </div>
             </section>

                <section>
                    <div className="contact-method field">
                        <span className="icon alt fa-home">
                            <h3>Adres</h3>
                        </span> 
                        {{address} && (
                            <a
                                href={`https://www.google.be/maps/search/${ encodeURI(
                                    address
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                { address }
                            </a>
                        )}
                    </div>
                </section>
          </div>
          <div className="contact-footer">
            <ul className="icons">
              <li style={{display:'none'}}>
                <a
                  href="https://twitter.com/fiberswitchBE"
                  className="icon fa-twitter"
                >
                  <span className="label">Twitter</span>
                </a>
              </li>
              <li>
                <a href="https://fb.com/fiberswitchBE" className="icon fa-facebook">
                  <span className="label">Facebook</span>
                </a>
              </li>
              <li style={{display:'none'}}>
                <a
                  href="https://gitlab.com/srasmond/fiberswitch"
                  className="icon fa-gitlab"
                >
                  <span className="label">GitLab</span>
                </a>
              </li>
            </ul>
            <div id="contact-logo-container">
              <div id="contact-logo">
                <Logo />
              </div>
            </div>
          </div>
    </>

)

ContactPage.propTypes = {
    contactBody: PropTypes.string,
    address: PropTypes.string,
    fb: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    btw: PropTypes.string,
  }
  
  ContactPage.defaultProps = {
    contactBody:
    `Neem gerust contact op via mail of het formulier op deze pagina en stel uw vraag!`,
    address: `Pastoor Wackensstraat 12, 9860 Scheldewindeke`,
    fb: `fiberswitch`,
    phone: `0474/83 56 17`,
    email: `info@fiberswitch.be`,
    btw: `BE 0730 625 675 `,
  }
  export default ContactPage;
