import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
    
  const Form = ( { name, _subject, action, method, successMessage, errorMessage } ) => {
    const [ alert, setAlert] = useState(null);
    const [ disabled, setDisabled] = useState(false);
    

    function handleSubmit(e) {
        
        e.preventDefault()
        if ( disabled ) return
        
        const form = e.target
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
    
        setDisabled(true);
        //console.log('xhr', xhr);
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
          //console.log(xhr.response);
          if (xhr.readyState !== XMLHttpRequest.DONE){
            //console.log('Network error');
            //return xhr.response;
            //setAlert( errorMessage );
            //setDisabled(false);          
          } 
          else {
           
            //return xhr.response;
            //throw new Error('Network error');
          }
          if (xhr.status === 200) {
            //console.log("200000")
            form.reset();
            setAlert( successMessage );
            setDisabled(false);
          } 
          else {
            console.error("error with xhr", xhr.errorMessage);
            setAlert( errorMessage );
            setDisabled(false);
          }
        };
        xhr.send(data);
      }

    return (
      <Fragment>
        <Helmet>
          <script src="https://www.google.be/recaptcha/api.js" />
        </Helmet>
        <form 
            className="Form"
            name={name}
            action={action}
            _subject={_subject}
            method={method}
            onSubmit={handleSubmit}
        >
          {{alert} && (
            <div className="Form--Alert">{alert}</div>
          )}
            <div className="field half first">
              <label htmlFor="name">Naam</label>
              <input 
                type="text" 
                name="naam" 
                id="name"
                placeholder="Uw naam"
                required
            />
            </div>
            <div className="field half">
              <label htmlFor="email">E-mail</label>
              <input 
                type="text" 
                name="email" 
                id="email"
                placeholder="E-mail"
                required
              />
            </div>
            <div className="email">
                <input
                    type="text"
                    placeholder="E-mail"
                    name="_gotcha"
                />
            </div>
            <div className="field">
              <label htmlFor="message">Uw bericht</label>
              <textarea 
                name="bericht"
                placeholder="Typ uw bericht"
                id="message" 
                rows="4"
                required
              >
                
                </textarea>
            </div>
            <ul className="actions">
              <li>
                {!!_subject && <input type="hidden" name="_subject" value={_subject} />}
                <input type="hidden" name="formulier" value={name} />
                <input 
                    type="submit" 
                    value="Verzenden" 
                    className="special"
                    disabled={disabled}
                />
              </li>
              <li>
                <input type="reset" value="Velden wissen" />
              </li>
            </ul>
          </form>
      </Fragment>
    )
}

Form.propTypes = {
    name: PropTypes.string,
    _subject: PropTypes.string,
    action: PropTypes.string,
    method: PropTypes.string,
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
  }
  
  Form.defaultProps = {
    name: 'Fiberswitch - Contact formulier',
    _subject: 'Fiberswitch',
    action: 'https://formspree.io/f/mgepydbb',
    method: 'POST',
    successMessage: 'Bedankt voor uw bericht, we nemen zo spoedig mogelijk contact met u op.',
    errorMessage:
      'Oei, er is een probleem waardoor u bericht niet kan doorgestuurd worden. Gelieve ons te contacteren via e-mail.'
  }

  export default Form;