// Logo.js
import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const Icon = () => {
  const data = useStaticQuery( graphql`
    query {
      file(name: { eq: "fs-favicon-white-dots" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(maxWidth: 200, pngQuality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  ` );

  return (
      <Img 
          fluid={ data.file.childImageSharp.fluid } 
          alt="logo" 
          loading="eager"
          className="fs-logo-icon"
      />
  );
};

export default Icon;