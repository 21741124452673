// Hook useOnClickOutside.js 
import { useEffect } from 'react';
import { navigate } from 'gatsby';

const useOnClickOutside = ( ref, handler, condition ) => {
    useEffect(
      () => {
        //setTimeout(() => {
        
          //if ( condition ) {
        const listener = event => {
          // Do nothing if clicking ref's element or descendent elements
          if ( ! ref.current || ref.current.contains(event.target) ) {
            return;
          }
          
          if ( condition ) {
            //handler( event );
            handler();
            navigate( "/" )
          }
        };
  
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
  
        return () => {
          document.removeEventListener('mousedown', listener);
          document.removeEventListener('touchstart', listener);
        };
      //}, 325)
       // }
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ ref, handler, condition ]
    );
  };

  export default useOnClickOutside;