import PropTypes from 'prop-types';
import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { HelmetDatoCms } from 'gatsby-source-datocms';
import sanitizeHtml from 'sanitize-html';
import pic01 from '../images/waranont-joe-T7qyLNPwgKA-unsplash.jpg';
import pic02 from '../images/nasa-Q1p7bh3SHj8-unsplash.jpg';
import pic03 from '../images/marcin-skalij-nXGw951o3Hk-unsplash_modified.jpg';
//import pic04 from '../images/scott-graham-5fNmWej4tAA-unsplash.jpg';
import Contact from "./Contact/Contact"


const Main = ( { route, article, articleTimeout, onCloseArticle, timeout, setWrapperRef } ) => {
  const sanitizer = ( html ) => {
    return sanitizeHtml( html );
  }

  let close = (
    <div
      className="close"
      onClick={() => {
       onCloseArticle()
      }}
      onKeyDown={() => {
        onCloseArticle() 
      }}
      role = "button"
      aria-label="Sluit categorie"
      tabIndex={ 0 }
    ></div>
  )

  const data = useStaticQuery( graphql`
        query Content {
          Intro: allDatoCmsInhoud(filter: {titel: {eq: "Intro"}}) {
            edges {
              node {
                inhoudNode {
                  childMarkdownRemark {
                    html
                  }
                }
                titel
                seoMetaTags {
                  tags
                }
              }
            }
          }
          About: allDatoCmsInhoud(filter: {titel: {eq: "Over"}}) {
            edges {
              node {
                inhoudNode {
                  childMarkdownRemark {
                    html
                  }
                }
                titel
                seoMetaTags {
                  tags
                }
              }
            }
          }
          Solutions: allDatoCmsInhoud(filter: {titel: {eq: "Oplossingen"}}) {
            edges {
              node {
                inhoudNode {
                  childMarkdownRemark {
                    html
                  }
                }
                titel
                seoMetaTags {
                  tags
                }
              }
            }
          }
          Projects: datoCmsProjecten {
            info
            titel
            seoMetaTags {
              tags
            }
          }
          ProjectContent: allDatoCmsProjectinhoud(sort: {fields: projectTitel, order: ASC}) {
            edges {
              node {
                projectTitel
                projectUrl
                tekstinhoudNode {
                  childMarkdownRemark {
                    html
                  }
                }
                afbeeldingen {
                  fluid(maxWidth: 200, imgixParams: {fm: "png", auto: "compress"}) {
                    ...GatsbyDatoCmsSizes
                  }
                }
              }
            }
          }
        }
    ` );
    const markdownData_intro  = data.Intro.edges[0].node;
    const markdownData_about  = data.About.edges[0].node;
    const markdownData_solutions  = data.Solutions.edges[0].node;
    const markdownData_projects  = data.Projects;
    //const markdownData_projectContent  = data.ProjectContent.edges[0].node;
    const html_intro  = markdownData_intro.inhoudNode.childMarkdownRemark.html;
    const html_about  = markdownData_about.inhoudNode.childMarkdownRemark.html;
    const html_solutions  = markdownData_solutions.inhoudNode.childMarkdownRemark.html;

    const projectsData= data.ProjectContent.edges.map( ( { node: project_Data } ) => {
      //let myId = removeSpaces( project_Data.alTitel ) + "_" + uid++
      return (
        <>
        <div
          className="markdown-content"
          dangerouslySetInnerHTML={ { __html: sanitizer( project_Data.tekstinhoudNode.childMarkdownRemark.html ) } }
        />
        { project_Data.afbeeldingen[0] &&
          <a id="project-logo-href" href={ project_Data.projectUrl } target="_blank" rel="noopener noreferrer">
            <Img 
              fluid={ project_Data.afbeeldingen[0].fluid } 
              alt="logo" 
              loading="eager"
              className="projects-logo"
            />
          </a>
        }
        </>
      )
        
      //);
    });

    //const html_projectsContent  = markdownData_projectContent.tekstinhoudNode.childMarkdownRemark.html;
    //var html_projectsImage;
    //if ( markdownData_projectContent.afbeeldingen[0] ) {
    //  html_projectsImage  = markdownData_projectContent.afbeeldingen[0].fluid;
    //}
    //else {
    //  html_projectsImage = "";
    //}
    //const html_projectsURL  = markdownData_projectContent.projectUrl;


    return (
      <div
        ref={setWrapperRef}
        id="main"
        style={timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${article === 'intro' ? 'active' : ''} ${
            articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <HelmetDatoCms seo={ markdownData_intro.seoMetaTags } />
          <h2 className="major">{ markdownData_intro.titel }</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <div
            className="markdown-content"
            dangerouslySetInnerHTML={ { __html: sanitizer( html_intro ) } }
          />
          {close}
        </article>

        <article
          id="work"
          className={`${article === 'oplossingen' ? 'active' : ''} ${
            articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <HelmetDatoCms seo={ markdownData_solutions.seoMetaTags } />
          <h2 className="major">{ markdownData_solutions.titel }</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <div
            className="markdown-content"
            dangerouslySetInnerHTML={ { __html: sanitizer( html_solutions ) } }
          />
          {close}
        </article>

        <article
          id="about"
          className={`${article === 'over' ? 'active' : ''} ${
            articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <HelmetDatoCms seo={ markdownData_about.seoMetaTags } />
          <h2 className="major">{ markdownData_about.titel }</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <div
            className="markdown-content"
            dangerouslySetInnerHTML={ { __html: sanitizer( html_about ) } }
          />
          {close}
        </article>

        <article
          id="projects"
          className={`${article === 'projecten' ? 'active' : ''} ${
            articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <HelmetDatoCms seo={ markdownData_projects.seoMetaTags } />
          <h2 className="major">{ markdownData_projects.titel }</h2>
          
          <div className="projects-content">
            { projectsData }
          </div>
          {close}
        </article>

        <article
          id="contact"
          className={`${article === 'contact' ? 'active' : ''} ${
            articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <Contact />
          {close}
        </article>
      </div>
    )
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  //setWrapperRef: PropTypes.func.isRequired,
  setWrapperRef: PropTypes.object.isRequired,
}

export default Main
